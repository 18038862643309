import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './nrsvg.css';

const NRSvg = ({ to, altText }) => {
  const navigate = useNavigate();
  const svgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  
  const handleDivClick = () => {
    navigate(to);
  };

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          // Trigger SVG animations when it comes into view
          animateSvgPaths();
        } else {
          // Optional: Reset paths when out of view
          resetSvgPaths();
        }
      },
      { threshold: 0.3 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, []);

  const animateSvgPaths = () => {
    if (!svgRef.current) return; // Ensure svgRef.current is defined

    const animationSettings = [
      { id: 'path_one', delay: 0, duration: 0.35 },
      { id: 'path_two', delay: 0.2, duration: 0.35 },
      { id: 'path_three', delay: 0.30, duration: 0.35 },
      { id: 'path_four', delay: 0.59, duration: 0.35 },
    ];

    animationSettings.forEach(({ id, delay, duration }) => {
      const path = svgRef.current.querySelector(`#${id}`);
      if (path) {
        const length = path.getTotalLength();
        path.style.strokeDasharray = `${length} ${length}`;
        path.style.strokeDashoffset = length;
        path.style.transition = `stroke-dashoffset ${duration}s ease ${delay}s`;
        requestAnimationFrame(() => {
          path.style.strokeDashoffset = '0';
        });
      }
    });

      // Apply the animation to #NR_fullshape
      const fullShape = svgRef.current.querySelector('#NR_fullshape');
      if (fullShape) {
        fullShape.style.transition = `opacity 1.2s ease-in`;  // Transition for opacity
        fullShape.style.fill = 'darkviolet';  // Apply color fill
        fullShape.style.opacity = '0';
        setTimeout(() => {
          fullShape.style.opacity = '1';  // Fade in to opacity 1
        }, 0);
      }
  };

  
  const resetSvgPaths = () => {
    if (!svgRef.current) return;  
    const paths = svgRef.current.querySelectorAll('.path');
    paths.forEach((path) => {
      path.style.strokeDasharray = '';
      path.style.strokeDashoffset = '';
      path.style.transition = '';
    });
  
    // Reset #NR_fullshape opacity to 0 when out of view
    const fullShape = svgRef.current.querySelector('#NR_fullshape');
    if (fullShape) {
      fullShape.style.opacity = '0';
    }
  };
  

  useEffect(() => {
    const updateStyle = () => {
      const svgElement = svgRef.current;
      if (svgElement) {
        svgElement.classList.remove('desktopStyle', 'mobileStyle');
        svgElement.classList.add(isDesktop ? 'desktopStyle' : 'mobileStyle');
      }
    };

    updateStyle();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isDesktop]);

  return (
    <div
      className={`click ${isVisible ? 'fade-in' : 'fade-out'}`}
      onClick={handleDivClick}
      style={{ cursor: 'pointer', display: 'inline-block' }}
      aria-label={altText}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="422.75"
        height="262.39"
        viewBox="-900 -650 5200 4036"
        preserveAspectRatio="xMidYMid meet"
        ref={svgRef}
      >
        <defs>
          <clipPath id="clip4">
            <path d="M3471.18 2557.86l-8.64 -8.61 9.06 8.56 0 0.05 -0.42 0zm-1079.13 -1075.66l-1306.22 -1301 1326.83 0c366.03,0 688.49,275.34 688.49,650.57 0,375.93 -329.29,650.58 -694.36,650.58 -4.93,0 -9.85,-0.05 -14.76,-0.15l0.02 0z"/>
          </clipPath>
          <clipPath id="clip3">
            <path d="M932.41 181.2l153.88 0 706.92 704.34 598.84 596.66 -0.02 0 998.83 999.36 80.53 76.06 0.02 0.01 0.19 0.19 0 0.04 -9.86 0.02 -153.94 0 -372.65 0 -2376.35 -2376.68 373.61 0z"/>
          </clipPath>
          <clipPath id="clip2">
            <path d="M400.77 560.38l1997.77 1997.42 -536.47 0 -1461.3 -1461.38 0 -536.04z"/>
          </clipPath>
          <clipPath id="clip1">
            <path d="M21.7 2557.8l0 -2376.42 379.07 379 0 536.04 -0.01 -0.01 0 1461.39 -379.06 0z"/>
          </clipPath>
        </defs>

        <g id="NRlogo">
          <path id="NR_fullshape" fill="lightgray" d="M21.7 2557.8l0 -2376.42 2376.84 2376.42 -536.47 0 -1461.31 -1461.39 0 1461.39 -379.06 0zm910.71 -2376.6l1480.25 0c366.03,0 688.49,275.34 688.49,650.57 0,375.93 -329.29,650.58 -694.36,650.58 -4.93,0 -9.85,-0.05 -14.76,-0.15l998.83 999.36 80.74 76.25 0 0.05 -9.86 0.02 -153.94 0 -372.65 0 -2376.35 -2376.68 373.61 0z"/>
          <g style={{ clipPath: 'url(#clip4)' }}>
            <path id="path_four" className="path" fill="none" d="M1010.22 364.48l1384.19 -0.03c620.11,0 760.54,924.57 -158.97,978.16 -129.24,7.53 -273.61,0.84 -405.07,-0.19 -17.79,-0.15 -30.82,-0.29 -37.07,-0.4"/>
          </g>
          <g style={{ clipPath: 'url(#clip3)' }}>
            <polyline id="path_three" className="path" fill="none" points="3351.65,2700.97 3191.18,2555.64 1087.17,451.43 769.94,140.27 644.69,15.88"/>
          </g>
          <g style={{ clipPath: 'url(#clip2)' }}>
            <polyline id="path_two" className="path" fill="none" points="241.69,667.65 423.71,854.18 2133.04,2569.29 2322.98,2774.36"/>
          </g>
          <g style={{ clipPath: 'url(#clip1)' }}>
            <line id="path_one" className="path" fill="none" x1="211.22" y1="34.49" x2="203.98" y2="2716.2"/>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default NRSvg;