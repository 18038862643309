import { configureStore } from "@reduxjs/toolkit";
import itemsReducer from './slice_with_reducer/itemsSlice';

const store = configureStore({
    reducer: {
        items:itemsReducer, 
    },
});
/* with seting up redcer here we make the state to be global available 
   so the component can access globaly to the state (in slices the state is local)
      
      items here is key of this object
    - in our case itemsReducer is varibale (we can use any)
    - itemsReducer is export default itemsSlice.reducer from the itemsSlice   
*/
export default store;   