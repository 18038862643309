import React from "react";
// import Stars from "../stars/stars"
import "./Glossy.css";


const Glossy = () => {

    return(
        <>

    <div  className="neutral">
    
        <div className="glossycont">
           
            <div className="glossy">
            
            {/* <Stars/>    */}
            </div>
     
        </div>
     
    </div>
    </>
    )
} 
export default Glossy;