import React, { useEffect, Suspense } from 'react';
import GLBModel from '../components/3danimLogo/glbModel';
import Glossy from '../components/glossycomp/Glossy';
import ParticleBackground from '../components/3dBackg/3dBackg';
import EnhancedParticleBackground from '../components/3dBackg/3DBackgroud';
import MilkyWayBackground from '../components/3dBackg/milkyWay';
import MilkyWayFog from '../components/3dBackg/milkyWayFog';
// import Glossy from '../components/glossycomp/Glossy';
import { ScrollControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Billboard } from '@react-three/drei';

import './home.css';

const Home = () => {
 /* to add body backrond on homepage only */
    useEffect(() => {
        // Add a unique class to the body when this component mounts
        document.body.classList.add('homepage-background');
    
        // Clean up by removing the class when the component unmounts
        return () => {
          document.body.classList.remove('homepage-background');
        };
      }, []);




    return(
        <>
        <div className="hpcontain"> 
          <Glossy/>  
        <div className='homepagewrap'>
            {/* <h3> Home page </h3>  */}
            <div className="homepageinner">
        {/* <div className='svggraphic'>
        
        </div> */}
      
      <div className='canvas-container'> 
        <Canvas camera={{position:[0, 0, 10.5]}} shadows
        fog={{ color: 'black', near: 1, far: 3 }} >
            <ambientLight castShadow />
            <ambientLight castShadow />
            <ambientLight castShadow />
            <spotLight castShadow position={[0,1,0]} color="white" intensity={1.2}/>
            <spotLight position={[0,0,1]} color="white" intensity={1.2}/>
            {/* <ScrollControls damping={0.8} maxSpeed={0.5} pages={0.75}>  */}
            {/* <ScrollControls damping={3} maxSpeed={0.2} pages={0.75}> */}
            <Suspense fullback={null}>
                
              <GLBModel />
              
            </Suspense>
            <ParticleBackground />
            {/* <EnhancedParticleBackground /> */}
            <Billboard>
            <MilkyWayFog />
            </Billboard>
            <MilkyWayBackground  />
            <ambientLight intensity={0.3} /> 
            
            {/* </ScrollControls> */}
            
        </Canvas>     
        </div> 
        </div>
      </div>
      </div>
    </>
    )
}
export default Home;