import React, { useState, useEffect } from 'react';
import './contact.css';

const Contact = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        questionType: 'Web Project',
    });
    const [error, setError] = useState('');

    useEffect(() => {
        setFadeIn(true); // Trigger the fade-in animation
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError(''); // Clear error when user types
    };

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.name || !formData.email || !formData.message) {
            return 'Please fill in all required fields.';
        }
        if (!emailRegex.test(formData.email)) {
            return 'Invalid email address.';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            const response = await fetch('https://www.novakrog.ch/sendEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), // Convert form data to JSON
            });

            const result = await response.json(); // Parse the JSON response

            if (response.ok) {
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    questionType: 'Web Project',
                });
                setError('');
            } else {
                setError(result.error || 'Something went wrong. Please try again.');
            }
        } catch (err) {
            setError('Failed to send your message. Please check your network connection.');
        }
    };

    return (
        <>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
            </style>

            <div className="contactpagetitle">
                <h3>Let’s Create Together</h3>
                <h4>Together, we create impact</h4>
            </div>

            <div className={`contact-page-wrapper ${fadeIn ? 'fade-in' : ''}`}>
                <div className="contact-page-backg">
                    <div className="contact-page">
                        <div className="contact-form-container">
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="First Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        placeholder="ex. 0775204681"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder="Your Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit" className="submit-button">Send</button>
                                {error && <p className="error-message">{error}</p>}
                            

                            {/* Display Success Message */}
                            {successMessage && (
                                <div className="success-message">
                                    <h4>{successMessage}</h4>
                                    <h5>I will get in touch with you shortly</h5>
                                </div>
                            )}
                            </form>
                        </div>
                        <div className="contact-details">
                            <h2 className="details-title">Get In Touch</h2>
                            <p className="details-box">
                                <strong>Address:</strong> <br /> Baumlihof Strasse 375, <br /> 4125 Riehen, <br /> SWITZERLAND
                            </p>
                            <p className="details-box">
                                <strong>Phone:</strong> <br /> +41 77 520 46 81
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
