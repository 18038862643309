import * as THREE from 'three';
import { useMemo, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';

const MilkyWayBackground = () => {
  const starsRef = useRef();

  // Generate positions for a more concentrated Milky Way shape
  const starPositions = useMemo(() => {
    const outerCount = 80; // Reduce outer stars for a lighter load
    const centerCount = 50;
    const positions = [];

    // Outer spiral pattern with random offsets
    for (let i = 0; i < outerCount; i++) {
      const angle = i * 0.25;
      const radius = 2.5 + i * 0.04;
      const x = radius * Math.cos(angle) + (Math.random() - 0.5) * 0.3;
      const y = (Math.random() - 0.5) * 1.2;
      const z = radius * Math.sin(angle) + (Math.random() - 0.5) * 0.3;
      positions.push([x, y, z]);
    }

    // Dense, foggy center cluster
    for (let i = 0; i < centerCount; i++) {
      const radius = Math.random() * 0.8;
      const angle = Math.random() * Math.PI * 2;
      const x = radius * Math.cos(angle) * 0.8;
      const y = (Math.random() - 0.5) * 0.3;
      const z = radius * Math.sin(angle) * 0.8;
      positions.push([x, y, z]);
    }

    return positions;
  }, []);

  // Star geometry with slight randomness in size
  const starGeometry = useMemo(() => new THREE.SphereGeometry(0.01, 6, 6), []);
  const starMaterial = useMemo(() => 
    new THREE.MeshStandardMaterial({
      color: new THREE.Color('hsl(200, 70%, 85%)'),
      emissive: new THREE.Color(0.2, 0.3, 0.6),
      emissiveIntensity: 0.7,
      transparent: true,
      opacity: 0.7,
    }), 
  []);

  // Position and rotate stars
  useEffect(() => {
    if (starsRef.current) {
      starPositions.forEach((position, i) => {
        const matrix = new THREE.Matrix4();
        matrix.setPosition(position[0], position[1], position[2]);
        starsRef.current.setMatrixAt(i, matrix);
      });
      starsRef.current.instanceMatrix.needsUpdate = true;
    }
  }, [starPositions]);

  // Rotate the Milky Way group slightly over time
  useFrame(() => {
    if (starsRef.current) starsRef.current.rotation.y += 0.0002;
  });

  return (
    <group position={[0, -0.15, 2.3]} rotation={[0.07, 0, 0]} scale={[1,1,1]}>
      <instancedMesh ref={starsRef} args={[starGeometry, starMaterial, starPositions.length]} />
    </group>
  );
};

export default MilkyWayBackground;
