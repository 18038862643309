import React from 'react';
import ItemsList from '../components/itemsList/itemsLits';
import './portfolio.css';

const Portfolio = () => {
 
    return(
        <>
        {/* <div className="portfolio">  */}
            {/* <h3 className="testPortfoliohead">Portfolio page</h3>  */}
            <ItemsList />
        {/* </div> */}
        </>
    )
}
export default Portfolio;