import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './overview.css';

const Overview = () => {
  const { id } = useParams();
  const item = useSelector((state) =>
    state.items.items.find((item) => item.id === parseInt(id))
  );

  const renderMedia = (mediaType, img, video) => {

    console.log("Rendering media for item:", item);
    if (item.mediaType === "video") {
    console.log("Rendering video:", item.video);
    }

   
    if (mediaType === 'img' && img && img !== 'null') {
      return <img className="mediaType" src={img} alt={item.title} />;
    }


    if (mediaType === 'video') {
      return (
        <video src={video} type="video/mp4">
          Your browser does not support the video tag.
        </video>
      )
    }

    
    // {mediaType === 'video' && (
    //     <video src={video} muted autoplay />
    // )}

    return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const targetElement = entry.target;
  
          console.log("Checking element:", targetElement);
  
          // Add a slight delay to ensure rendering is complete
          setTimeout(() => {
            const video = targetElement.querySelector("video");
  
            if (entry.isIntersecting) {
              console.log("Element is in view");
  
              if (video) {
                console.log("Video found:", video);
  
                if (video.paused) {
                  video.muted = true; // Ensure autoplay works
                  video.play()
                    .then(() => console.log("Video started playing"))
                    .catch((err) => console.error("Autoplay failed:", err));
                }
              } else {
                console.log("No video found in the target element (delayed check).");
              }
  
              targetElement.classList.add("fadeIn");
            } else {
              targetElement.classList.remove("fadeIn");
  
              if (video && !video.paused) {
                video.pause();
                video.currentTime = 0; // Reset to the start
              }
            }
          }, 100); // Adjust delay if needed
        });
      },
      { threshold: 0.2 }
    );
  
    const contentItems = document.querySelectorAll(".contentitem");
    contentItems.forEach((item) => observer.observe(item));
  
    return () => observer.disconnect();
  }, []);
  


  return (
    <div className="wrapitem">
      <div className="maincontent">
     
        <div className="contentitem">
        <div className="draw-rect">
          
          <div className="title">
            <h2>{item.title}</h2>
          </div>
          <div className="images">
            {Array.isArray(item.img) ? (
              item.img.map((src, index) => (
                <img key={index} src={src} alt={`${item.title} ${index + 1}`} />
              ))
            ) : (
              <img src={item.img} alt={item.title} />
            )}
          </div>

        </div>
          <div className="descript">
            <h3>{item.subtitle}</h3>
            <p>{item.overview}</p>
          </div>
        </div>
        <div className="contentitem">    
            {item.mediaType && renderMedia(item.mediaType, item.img, item.video)}
        </div>

        {item.design && (
          <div className="contentitem">
            <div className="title">
              <h2>{item.design}</h2>
            </div>
            {item.design_img && (
              <div className="images">
                {Array.isArray(item.design_img) ? (
                  item.design_img.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`${item.title} design ${index + 1}`}
                    />
                  ))
                ) : (
                  <img src={item.design_img} alt={`${item.title} design`} />
                )}
              </div>
            )}
            <div className="descript">
              <h3>{item.design_subtitle}</h3>
              <p>{item.design_descript}</p>
            </div>
          </div>
        )}

        {item.features && (
          <div className="contentitem">
            <div className="draw-rect">
            <div className="title">
              <h2>{item.features}</h2>
            </div>
            {item.features_img && (
              <div className="images">
                <img src={item.features_img} alt={`${item.title} features`} />
              </div>
            )}
          </div>

            <div className="descript">
              <h3>{item.features_subtitle}</h3>
              <p>{item.features_descript}</p>
            </div>
          </div>
        )}

        {item.technology && (
          <div className="contentitem">
          <div className="draw-rect">
            <div className="title">
              <h2>{item.technology}</h2>
            </div>
            {item.tech_img && (
              <div className="techimages">
                {Array.isArray(item.tech_img) ? (
                  item.tech_img.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`${item.title} tech ${index + 1}`}
                      style={{ maxWidth: '10%', height: 'auto', padding: '0.2em' }}
                    />
                  ))
                ) : (
                  <img
                    src={item.tech_img}
                    alt={item.technology}
                    style={{ minWidth: '75%', height: 'auto' }}
                  />
                )}
              </div>
          )}
          </div>
            <div className="descript">
              <h3>{item.technology_subtitle}</h3>
              <p className='techdescript'>{item.tech_descript}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
