import React from 'react';
import { useEffect, useState } from 'react';
import './about.css';

const About = () => {

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
      setFadeIn(true); // Trigger the fade-in animation
    }, []);

 
    return(
        <>             
          {/* <div className='aboutnovakrog'><h2>About NovakRog</h2></div> */}
          <div className="mainservicetitle">
                    <h3>Welcome to NovakRog Web</h3>
                    <h4>Discover my work in design and development</h4>
                </div>

          <div className={`aboutwraper ${fadeIn ? 'fade-in' : ''}`}>
            <div className='aboutbackg'>
                <div className="aboutcontainer">
                <div className="aboutimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                <div className="aboutme"> 
                  <h2>About novakrog</h2>
                  <p className='aboutmedescript'>
                  I'm Novak Roganovikj, a multidisciplinary designer and front-end web developer with a passion 
                  for crafting modern, user-centric digital experiences. With a strong foundation in design 
                  and a specialization in React, JavaScript, and web technologies, I merge visual creativity 
                  with clean, efficient coding in order to deliver seamless, interactive websites.
                  </p>
                  <p className='aboutdescript'>
                  Based in Basel, Switzerland, I can work with you locally or remotely to bring your ideas to life. 
                  Whether you need a creative front-end developer or a custom website, let’s work together! 
                  Contact me via the form or phone (0041 77 520 46 81).
                  </p>
                </div>
                </div>
                </div>
                {/* <div className='button'>
                <button> My workrs </button>
                </div> */}
            </div>

        </>
    )
}
export default About;