import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home.js'
import About from './pages/about.js'
import Portfolio from './pages/portfolio.js';
import Overview from './pages/overview.js'
import Service from './pages/service.js';
import Videos from './pages/videos.js';
import Contact from './pages/contact.js';
import Login from './pages/login.js';
import HeaderNav from './components/headerNav/headerNav.js';
import React, { useState } from 'react';
import './normalize.min.css';

// import 'https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css'; // Normalize.css
// import 'https://cdnjs.cloudflare.com/ajax/libs/Meyerweb-reset/2.0/reset.min.css'; // CSS Reset*/



function App() {

  const [navOpen, setNavOpen] = useState(false); 
  return (
    <>
    <div className="wrappage"> 
      <BrowserRouter>
      
        <HeaderNav setNavOpen={setNavOpen}/>
        {/* <div className={`main-content ${navOpen ? 'nav-open': ''}`}> */}
       
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />           
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/overview/:id" element={<Overview />} />
            <Route path="/service" element={<Service />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
        </Routes>
         {/* temporary div for if svg workks well - delete later */}
      {/* <div>    
         <NRSvg to="/svg/#" altText="Your SVG Image" />
      </div> */}
    
      </BrowserRouter>
     
      </div>
    </>
  );
}

export default App;