import React from 'react';
import { NavLink } from "react-router-dom";
import './headerNav.css';

const HeaderNav = ({setNavOpen}) =>{

        const navresponsive = () => {
            let mainavbarId = document.getElementById("mainavbar");
            
            if(mainavbarId.className === "mainnavbar") {
                mainavbarId.className += " responsive";
                setNavOpen(true);
            } else {
                mainavbarId.className = "mainnavbar";
                setNavOpen(false);
            }
        }

  return(
    <>
    
    <header>
                <div className="navigation">
                    <div className="mainnavbar" id="mainavbar">
                            <nav>
                                <div className="navlogo"><object type="image/svg+xml" data="./svg/NRlogo_mobile.svg"></object></div>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/about">About</NavLink></li>
                                    <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                                    <li><NavLink to="/service">Service</NavLink> </li>
                                    <li><NavLink to="/videos">Videos</NavLink></li>
                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                    <li><NavLink to="/login">Login</NavLink></li>
                                </ul>
                                <div className="hambicon"><a href="#" onClick={()=>navresponsive()}><img src="./img/hambicon.png" /></a></div>
                            </nav>
                        </div>
                    </div> 
            </header> 
    </>   
  )  
}

export default HeaderNav;
