import React, { useState } from 'react';
import './login.css';

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError(''); // Clear error when user types
    };

    const handleLogin = (e) => {
        e.preventDefault();
        const { username, password } = formData;

        if (!username || !password) {
            setError('Please fill in all fields.');
            return;
        }
        if (username !== 'client@gmail.com' || password !== 'basel2025') {
            setError('Incorrect username or password.');
            return;
        }

        // Successful login
        setIsLoggedIn(true);
        setFormData({ username: '', password: '' });
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setError('');
    };

    return (
        <>
          <div className="loginpagetitle">
                    <h3>Client Login</h3>
                    <h4>Explore project progress</h4>
                </div>

        <div className='login-wrapper'>
        <div className='login-backg'>    
        <div className="login-page">
            {!isLoggedIn ? (
                <div className="login-form-container">
                    <h1 className="login-title">client login</h1>
                    <form className="login-form" onSubmit={handleLogin} autoComplete="off">
                        <div className="login-form-group">
                            {/* <label htmlFor="username">USERNAME</label> */}
                            <input
                                type="email"
                                id="username"
                                name="username"
                                placeholder='username'
                                value={formData.username}
                                onChange={handleChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="form-group">
                            {/* <label htmlFor="password">PASSWORD</label> */}
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder='password'
                                value={formData.password}
                                onChange={handleChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                        <button type="submit" className="login-btn">Login</button>
                        {error && <p className="error-message">{error}</p>}
                    </form>

                </div>
            ) : (
                <div className="welcome-message">
                    <h1 className="welcome-title">Welcome to Client Page</h1>
                    <p className="welcome-subtitle">
                        Let’s collaborate together on your project.
                    </p>
                    <button className="logout-btn" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            )}
         </div>   
        </div>
        </div>
        </>
    );
};

export default Login;
