import React from 'react';
import './service.css';

const Service = () => {
 
    return(
        <>             
          {/* <div className='servicenovakrog'><h2>About NovakRog</h2></div> */}
            
            
          <div className="mainservicetitle">
                    <h3>Services I can provide</h3>
                    <h4>Discover and filter by project type</h4>
                </div>
                
            <div className='servicewraper'>
                <div className="servicecontainer">
                    <div className="serviceimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                    <div className="serviceme"> 
                        <h2>Web Projects</h2>
                        <p className='servicemedescript'>
                            An energetic creative with more than 13 years of design experience. 
                            has been responsible for implementing concepts for clients 
                            across all industries. When Dael isn't busy educating people service 
                            the difference between fonts and typefaces, he can be spotted exercising in Bern.
                        </p>
                    </div>
                </div>

                <div className="servicecontainer">
                    <div className="serviceimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                <div className="serviceme"> 
                <h2>Design Projects</h2>
                <p className='servicemedescript'>
                    An energetic creative with more than 13 years of design experience. 
                   has been responsible for implementing concepts for clients 
                    across all industries. When Dael isn't busy educating people service 
                    the difference between fonts and typefaces, he can be spotted exercising in Bern.
                </p>
                </div>
                
                </div>
              
                <div className="servicecontainer">
                    <div className="serviceimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                        <div className="serviceme"> 
                            <h2>Other Projects</h2>
                            <p className='servicemedescript'>
                                An energetic creative with more than 13 years of design experience. 
                                has been responsible for implementing concepts for clients 
                                across all industries. When Dael isn't busy educating people service 
                                the difference between fonts and typefaces, he can be spotted exercising in Bern.
                            </p>
                    </div>
                </div>
               
                <div className="servicecontainer">
                    <div className="serviceimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                        <div className="serviceme"> 
                            <h2>Other Projects</h2>
                            <p className='servicemedescript'>
                                An energetic creative with more than 13 years of design experience. 
                                has been responsible for implementing concepts for clients 
                                across all industries. When Dael isn't busy educating people service 
                                the difference between fonts and typefaces, he can be spotted exercising in Bern.
                            </p>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Service;