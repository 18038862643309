import React from 'react';
import './videos.css';

const Videos = () => {
 
    return(
        <>
            <h3 className="testVideoshead">Videos page</h3> 
        </>
    )
}
export default Videos;